import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import invoke from '../rpc';
import Dropdown from 'react-dropdown';
import Config from '../constants';

const invokeGameService = invoke('GameService');

const Games = () => {
  const [games, setGames] = useState([]);
  const [environment, setEnvironment] = useState(JSON.parse(window.localStorage.getItem('GameRoundEnvironmentId')) || null);
  const [environmentPath, setEnvironmentPath] = useState(JSON.parse(window.localStorage.getItem('GameRoundEnvironmentPath')) || null);
  const [allEnviroment, setallEnviroment] = useState(JSON.parse(window.localStorage.getItem('AllEnvironmentConfigNew')) || Config.EnvironmentConfig)

  let environments =[];
  allEnviroment.map((element, index) => (environments.push(element.name)));
  useEffect(() => {
    if(environment!=''){
      const index = allEnviroment.findIndex(o => o.name === environment);
      if(index != -1){
        setEnvironmentPath(allEnviroment[index].path);
        invokeGameService('GetGames',{path: allEnviroment[index].path}).then(data => {
          setGames(data);
        });
      }
    }
  }, []);

  const onEnvironmentchange = option =>{
    setEnvironment(option.value);
    
}
  const search = () =>{
    const index = allEnviroment.findIndex(o => o.name === environment);
    if(index != -1){
      setEnvironmentPath(allEnviroment[index].path);
      window.localStorage.setItem('GameRoundEnvironmentId', JSON.stringify(environment));
    window.localStorage.setItem('GameRoundEnvironmentPath', JSON.stringify(allEnviroment[index].path));
    invokeGameService('GetGames',{path: allEnviroment[index].path}).then(data => {
      setGames(data);
    });
    }
  }
  const suspend = (id, isSuspended) =>
    invokeGameService('SuspendGame',{path: environmentPath}, id, isSuspended).then(() =>
      setGames(
        games.map(({ gameId, ...game }) => (gameId === id ? { gameId, ...game, isSuspended } : { gameId, ...game }))
      )
    );
  return (
    <>
     <form>
      <div></div>
        <div className="form-group row mt-3">
        <div className="col-sm-2">
             <Dropdown value={(environment == null || environment == "All") ? "" : environment} className="form-control" onChange={onEnvironmentchange} options={environments} placeholder="Select Enviroment" type="text" />
          </div>
          <div className="col-sm-2">
            <button className="btn btn-primary btn-block" type="button" onClick={search}>
              Search
            </button>
          </div>
        </div>
      
      </form>
    <table className="table table-sm table-bordered table-hover table-striped">
      <caption>Games</caption>
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">gameId</th>
          <th scope="col">engine</th>
          <th scope="col">suspension</th>
        </tr>
      </thead>
      <tbody>
        {games.map(({ gameId, engine, isSuspended }, index) => (
          <tr key={gameId}>
            <td>{index}</td>
            <td>
              <Link className="nav-link" to={`/games/${gameId}`}>
                {gameId}
              </Link>
            </td>
            <td>{engine}</td>
            <td>
              <button className="btn btn-primary" onClick={() => suspend(gameId, !isSuspended)}>
                {isSuspended ? 'Unsuspend' : 'Suspend'}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </>
  );
};

export default Games;
