const Config = {
   EnvironmentConfig :  [
        {
          _id: 1,
          path: 'http://assets.admin-prod.bollytech.topspingames.net',
          name: 'Bollytech',
        },
        {
          _id: 2,
          path: 'http://assets.admin-prod-parimatch.topspingames.net',
          name: 'Parimatch',
        },
        {
          _id: 3,
          path: 'http://assets.admin-topspin-stwallet.topspingame.com',
          name: 'St Wallet',
        },
        {
          _id: 4,
          path: 'http://assets.admin-prod-hub-b2b-eu.topspingames.net',
          name: 'hub-b2b-eu',
        },
        {
          _id: 5,
          path: 'http://assets.admin-prod-hub-b2b-as.topspingames.net',
          name: 'hub-b2b-as',
        },
        {
          _id: 6,
          path: 'http://assets.admin-prod-hub-b2c-eu.topspingames.net',
          name: 'hub-b2c-eu',
        },
        {
          _id: 7,
          path: 'http://assets.admin-prod-hub-b2c-as.topspingames.net',
          name: 'hub-b2c-as',
        }
      ]
}

  export default Config;