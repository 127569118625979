import React from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import Games from './game/games';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import IncompleteGameRounds from './gameRound/gameRounds';
import GameRounds from './gameRoundHistory/gameRounds';
import GameRound from './gameRoundHistory/gameRound';
import Game from './game/game';
import Log from './log/log';
import Environments from './enviornment/Environments';
//dummy

const App = () => (
  <Router>
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link className="navbar-brand" to="/">
          RGS Admin
          <Redirect to="/gameRoundHistory"></Redirect>
        </Link>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/games">
                Games
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/incompleteGameRounds">
                Incomplete Game Rounds
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/gameRoundHistory">
                Game Round History
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/environments">
              Environments
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <main>
        <Route path="/games" exact component={Games} />
        <Route path="/games/:gameId" component={Game} />
        <Route path="/incompleteGameRounds" exact component={IncompleteGameRounds} />
        <Route path="/gameRoundHistory" exact component={GameRounds} />
        <Route path="/gameRoundHistory/:gameRoundId" component={GameRound} />
        <Route path="/log/:gameRoundId" component={Log} />
        <Route path="/environments" component={Environments} />
      </main>
      <footer className="text-center">RGS Admin</footer>
    </div>
  </Router>
);

export default App;
