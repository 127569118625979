const invoke = (serviceName) => async (methodName, ...args) => {
  let url = ''
  if(args && args[0].path){
    url = args[0].path;
    delete args[0].path;
    if(Object.keys(args[0]).length == 0){
      args.splice(0,1)
    }
  }
  const response = await fetch(url + '/api/rpc', {
    method: 'POST',
    body: JSON.stringify({ serviceName, methodName, arguments: args }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const { error, result } = await response.json();
  if (error) {
    throw new Error(error);
  }
  return result;
};

export default invoke;
