import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import invoke from '../rpc';
import { Rings } from 'react-loader-spinner';
import Dropdown from 'react-dropdown';
import { CSVLink, CSVDownload } from "react-csv";
import { components } from "react-select";
import Config from '../constants';



const invokeGameRoundHistoryService = invoke('GameRoundHistoryService');
const size = 300;

Array.prototype.sum = function (prop) {
  var total = 0
  for (var i = 0, _len = this.length; i < _len; i++) {
    total += this[i][prop]
  }
  return total
}
let gameIdArr = ["All Games"]

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers, 
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}


const invokeGameService = invoke('GameService');

const GameRounds = () => {
  const [visible, setVisible] = useState(false);
  const [gameRoundsTotal, setGameRoundsTotal] = useState([]);
  const [unsortedGameRounds, setunsortedGameRounds] = useState([]);
  const [gameRounds, setGameRounds] = useState([]);
  const [environment, setEnvironment] = useState(JSON.parse(window.localStorage.getItem('GameRoundEnvironmentId')) || null);
  const [environmentPath, setEnvironmentPath] = useState(JSON.parse(window.localStorage.getItem('GameRoundEnvironmentPath')) || null);
  const [totalStake, setTotalStake] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [totalGGR, setTotalGGR] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [allEnviromentSelected, setallEnviromentSelected] = useState(JSON.parse(window.localStorage.getItem('GameRoundAllEnvironmentSelected')) || false);
  const [currencyBucket, setCurrencyBucket] = useState([]);
  const [currencies, setCurrencies] = useState(["All Currencies"]);

  const [lastTimeStamp, setLastTimeStamp] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [gameID, setGameId] = useState(JSON.parse(window.localStorage.getItem('GameRoundHistoryGameId')) || null);
  const [games, setGames] = useState(JSON.parse(window.localStorage.getItem('GameSearchDetails')) || []);
  const [currency, setCurrency] = useState(JSON.parse(window.localStorage.getItem('GameRoundHistoryCurrency')) || null);
  const [allEnviroment, setallEnviroment] = useState(JSON.parse(window.localStorage.getItem('AllEnvironmentConfigNew')) || Config.EnvironmentConfig)

 
  gameIdArr = ["All Games"];
  games.map(({ gameId }, index) => (gameIdArr.push(gameId)));
  let environments =["All"];
  allEnviroment.map((element, index) => (environments.push(element.name)));


  const gameRoundHistoryServiceParams = JSON.parse(window.localStorage.getItem('GameRoundHistoryServiceParams'));
  let [tsCreatedFrom, tsCreatedTo, playerId, gameRoundId, gameId, environmentId] = Array.from({ length: 5 }, useRef);
  (gameRoundHistoryServiceParams != null) && ([tsCreatedFrom, tsCreatedTo, playerId, gameRoundId, gameId, environmentId] = gameRoundHistoryServiceParams);
  const onCurrencyChange = option => {
    setCurrency(option.value === "All Currencies" ? null : option.value);
  }

  const onEnvironmentchange = option =>{
    setEnvironment(option.value);
    if(option.value == "All"){
      setallEnviromentSelected(true);
    }else {
      setallEnviromentSelected(false);
      const index = allEnviroment.findIndex(o => o.name === option.value);
      if(index != -1){
        setallEnviromentSelected(false);
        setEnvironmentPath(allEnviroment[index].path);
        invokeGameService('GetGames',{path: allEnviroment[index].path}).then((result) => {
          setGames(result);
          gameIdArr = ["All Games"]
          result.map(({ gameId }, index) => (gameIdArr.push(gameId)));
          window.localStorage.setItem('GameSearchDetails', JSON.stringify(result));
        })
      }
    }
  }
  const getGMTDateTimeString = (localDateTimeString, currentTime = false) => {
    let localDateObject = null;
    if (currentTime) {
      localDateObject = new Date(localDateTimeString);
      let date = new Date();
      localDateObject.setHours(date.getHours(), date.getMinutes(), date.getSeconds());
    } else {
      localDateObject = new Date(localDateTimeString);
      localDateObject.setHours(0, 0, 0);
    }

    let day = localDateObject.getUTCDate();
    day = day < 10 ? `0${day}` : day;
    let month = (localDateObject.getUTCMonth() + 1);
    month = month < 10 ? `0${month}` : month;
    const year = localDateObject.getUTCFullYear();
    let hours = localDateObject.getUTCHours();
    hours = hours < 10 ? `0${hours}` : hours;
    let minutes = localDateObject.getUTCMinutes();
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    let seconds = localDateObject.getUTCSeconds();
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    let milliseconds = localDateObject.getUTCMilliseconds();
    milliseconds = milliseconds < 10 ? `00${milliseconds}` : milliseconds < 100 ? `0${milliseconds}` : milliseconds;
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }

  function onchange(option) {

    setGameId(option.value === "All Games" ? null : option.value);

  }
  useEffect(() => {
    if(environment!=''){
      if(environment == "All"){
        setallEnviromentSelected(true);
      }else {
        setallEnviromentSelected(false);
        const index = allEnviroment.findIndex(o => o.name === environment);
      if(index != -1){
          setEnvironmentPath(allEnviroment[index].path);
          invokeGameService('GetGames',{path: allEnviroment[index].path}).then((result) => {
          setGames(result);
          gameIdArr = ["All Games"]
          result.map(({ gameId }, index) => (gameIdArr.push(gameId)));
          window.localStorage.setItem('GameSearchDetails', JSON.stringify(result));
        })
        }
      }
    }
  }, []);

  let counter = 0;
  let arr = [
    {
      path: 'http://assets.admin-prod-parimatch.topspingames.net'
    },
    {
      path: 'http://assets.admin-prod.bollytech.topspingames.net'
    }
  ];
   let totStk = 0;
   let totRet = 0;
   let totGgr = 0;
   let totDoc = 0;
   let currencyBucketArr = [];
   const findAggregatedGGR = () => {
    return new Promise((resolve) => {
      if(counter == 0){
              totStk = 0;
              totRet = 0;
              totGgr = 0;
              totDoc = 0;
            }
      const gameRoundSearchParams = JSON.parse(window.localStorage.getItem('GameRoundHistoryServiceParams'));
      let filter = {};
      if (currency && currency != 'Blank') {
        filter = {
          filter: {
            terms: {
              currency: [currency.toUpperCase(), currency.toLowerCase()]
            }
          }
        }
      }
      invokeGameRoundHistoryService('SearchGameRoundHistory', {
        path: allEnviromentSelected? allEnviroment[counter].path: environmentPath,
        _source: ["totalStake", "totalReturn"],
        query: {
          bool: {
            must: [
              ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) || (tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value)
                ? [
                  {
                    range: {
                      tsCreated: {
                        ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) ? { gte: getGMTDateTimeString((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value)) } : { gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)) }),
                        ...((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value) ? { lte: getGMTDateTimeString((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value), true) } : { lte: getGMTDateTimeString(new Date().toString(), true) }),
                      },
                    },
                  },
                ]
                : [
                  {
                    range: {
                      tsCreated: {
                        gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)),
                        lte: getGMTDateTimeString(new Date().toString(), true)
                      }
                    }
                  }
                ]),
              ...((gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) ? [{ term: { _id: +(gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) } }] : []),
              ...(playerId?.current?.value || gameRoundSearchParams[2]?.current?.value ? [{ term: { playerId: playerId?.current?.value || gameRoundSearchParams[2]?.current?.value } }] : []),
              ...((gameID == "Blank" ? "" : gameID) ? [{
                wildcard: {
                  gameId: `*${gameID.split("-")[gameID.split("-").length - 1]}*`
                }
              }] : []),
              { term: { isPlayForFun: false } }
            ],
            ...filter

          },
        },
        "aggs": {
          "totalStake":
          {
            "sum":
              { "field": "totalStake" }
          },
          "totalReturn": {
            "sum":
              { "field": "totalReturn" }
          },
          "docsCount": {
            "value_count": {
              "field": "totalStake"
            }
          },
          "uniqueCurrencies": {
            "terms": {
              "field": "currency"
            }
          }
        }
      }).then(({ aggregations }) => {
            
        totStk += aggregations?.totalStake?.value ? aggregations.totalStake.value : 0;
        totRet += aggregations?.totalReturn?.value ? aggregations.totalReturn.value : 0;
        totGgr += (aggregations?.totalStake?.value ? aggregations.totalStake.value : 0) - (aggregations?.totalReturn?.value ? aggregations.totalReturn.value : 0);
        totDoc += aggregations?.docsCount?.value ? aggregations.docsCount.value : 0;
        setTotalStake(totStk);
        setTotalReturn(totRet);
        setTotalGGR(totGgr);
        setTotalDocs(totDoc);
       
        const buckets = (aggregations?.uniqueCurrencies?.buckets) ? aggregations.uniqueCurrencies.buckets : [];
        if (currency == null) {
          setCurrencies(["All Currencies", ...buckets.map(currData => currData.key.toUpperCase())]);
        }
        if(!buckets.length){
          counter++;
          if(allEnviromentSelected && counter < allEnviroment.length){
            findAggregatedGGR()
          }
          resolve();
          return;
        }
        let bucketsByCurrency = buckets.map(currData => {
          return invokeGameRoundHistoryService('SearchGameRoundHistory', {
            path: allEnviromentSelected? allEnviroment[counter].path: environmentPath,
            _source: ["totalStake", "totalReturn"],
            query: {
              bool: {
                must: [
                  ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) || (tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value)
                    ? [
                      {
                        range: {
                          tsCreated: {
                            ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) ? { gte: getGMTDateTimeString((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value)) } : { gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)) }),
                            ...((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value) ? { lte: getGMTDateTimeString((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value), true) } : { lte: getGMTDateTimeString(new Date().toString(), true) }),
                          },
                        },
                      },
                    ]
                    : [
                      {
                        range: {
                          tsCreated: {
                            gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)),
                            lte: getGMTDateTimeString(new Date().toString(), true)
                          }
                        }
                      }
                    ]),
                  ...((gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) ? [{ term: { _id: +(gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) } }] : []),
                  ...(playerId?.current?.value || gameRoundSearchParams[2]?.current?.value ? [{ term: { playerId: playerId?.current?.value || gameRoundSearchParams[2]?.current?.value } }] : []),
                  ...((gameID == "Blank" ? "" : gameID) ? [{
                    wildcard: {
                      gameId: `*${gameID.split("-")[gameID.split("-").length - 1]}*`
                    }
                  }] : []),
                  { term: { isPlayForFun: false } }
                ],
                filter: {
                  terms: {
                    currency: [currData.key.toUpperCase(), currData.key.toLowerCase()]
                  }
                }

              },
            },
            "aggs": {
              "totalStake":
              {
                "sum":
                  { "field": "totalStake" }
              },
              "totalReturn": {
                "sum":
                  { "field": "totalReturn" }
              },
              "uniqueCurrencies": {
                "terms": {
                  "field": "currency"
                }
              }
            }
          });
        });
        Promise.all(bucketsByCurrency).then(bucketData => {
          console.log(bucketData);
          const aggregatedData = bucketData.map(data => {
            const bucket = data.aggregations.uniqueCurrencies.buckets[0];
            let doc_count = bucket.doc_count;
            let totalStk = data?.aggregations?.totalStake?.value ? data.aggregations.totalStake.value : 0;
            let totalRet = data?.aggregations?.totalReturn?.value ? data.aggregations.totalReturn.value:0;
            return {
              currency: bucket.key,
              doc_count: doc_count,
              totalStake: totalStk,
              totalReturn: totalRet
            }
          });

          if(currencyBucketArr.length){
            aggregatedData.forEach((value) =>{
              const index = currencyBucketArr.findIndex(o => o.currency == value.currency);
              if(index != -1){
                currencyBucketArr[index].doc_count += value.doc_count;
                currencyBucketArr[index].totalStake += value.totalStake;
                currencyBucketArr[index].totalReturn += value.totalReturn;
              } else {
                currencyBucketArr.push(value)
              }
            })
            setCurrencyBucket(currencyBucketArr);
            currencyBucketArr = currencyBucketArr;
          } else {
            setCurrencyBucket(aggregatedData);
            currencyBucketArr = aggregatedData;
          }
         
          counter++;
          if(allEnviromentSelected && counter < allEnviroment.length){
            findAggregatedGGR()
          }
          resolve();
        })
      }).catch(() => {
        resolve();
      })
    })
  }
  
  const search = (isNew) => {
    if(allEnviromentSelected){
      counter = 0;
      currencyBucketArr = [];
      isNew && findAggregatedGGR();
      window.localStorage.setItem('GameRoundAllEnvironmentSelected', JSON.stringify(allEnviromentSelected));
      window.localStorage.setItem('GameRoundEnvironmentId', JSON.stringify(environment));
      setGameRounds([]);
      return;
    }
    if(environmentPath == null){
      alert("Please Select the Environment")
    }
    setVisible(true);
    console.log("Search Started");
    const gameRoundSearchParams = JSON.parse(window.localStorage.getItem('GameRoundHistoryServiceParams'));

    window.localStorage.setItem('GameRoundHistoryGameId', JSON.stringify(gameID));
    window.localStorage.setItem('GameRoundHistoryCurrency', JSON.stringify(currency));
    window.localStorage.setItem('GameRoundEnvironmentId', JSON.stringify(environment));
    window.localStorage.setItem('GameRoundEnvironmentPath', JSON.stringify(environmentPath));
    window.localStorage.setItem('GameRoundHistoryServiceParams', JSON.stringify([
      {
        current: {
          value: isNew ? tsCreatedFrom.current.value : gameRoundSearchParams[0]?.current.value
        }
      },
      {
        current: {
          value: isNew ? tsCreatedTo.current.value : gameRoundSearchParams[1]?.current.value
        }
      },
      {
        current: {
          value: isNew ? playerId.current.value : gameRoundSearchParams[2]?.current?.value
        }
      },
      {
        current: {
          value: isNew ? gameRoundId.current.value : gameRoundSearchParams[3]?.current?.value
        }
      },
      {
        current: {
          value: isNew ? gameId.current.value : gameRoundSearchParams[4]?.current?.value
        }
      }
    ]))
    let filter = {};
    if (currency && currency != 'Blank') {
      filter = {
        filter: {
          terms: {
            currency: [currency.toUpperCase(), currency.toLowerCase()]
          }
        }
      }
    }
    let search_after = {};
    if (!isNew && unsortedGameRounds.length > 0 && lastTimeStamp.length > 0) {
      search_after.search_after = [];
      search_after.search_after.push(lastTimeStamp);
    }

    isNew && findAggregatedGGR();
    invokeGameRoundHistoryService('SearchGameRoundHistory', {
      path: environmentPath,
      size,
      _source: {
        excludes: ['commands'],
      },
      query: {
        bool: {
          must: [
            ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) || (tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value)
              ? [
                {
                  range: {
                    tsCreated: {
                      ...((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value) ? { gte: getGMTDateTimeString((tsCreatedFrom?.current?.value || gameRoundSearchParams[0]?.current?.value)) } : { gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)) }),
                      ...((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value) ? { lte: getGMTDateTimeString((tsCreatedTo?.current?.value || gameRoundSearchParams[1]?.current?.value), true) } : { lte: getGMTDateTimeString(new Date().toString(), true) }),
                    },
                  },
                },
              ]
              : [
                {
                  range: {
                    tsCreated: {
                      gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)),
                      lte: getGMTDateTimeString(new Date().toString(), true)
                    }
                  }
                }
              ]),
            ...((gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) ? [{ term: { _id: +(gameRoundId?.current?.value || gameRoundSearchParams[3]?.current?.value) } }] : []),
            ...(playerId?.current?.value || gameRoundSearchParams[2]?.current?.value ? [{ term: { playerId: playerId?.current?.value || gameRoundSearchParams[2]?.current?.value } }] : []),
            ...((gameID == "Blank" ? "" : gameID) ? [{
              wildcard: {
                gameId: `*${gameID.split("-")[gameID.split("-").length - 1].toLowerCase()}*`
              }
            }] : []),
            { term: { isPlayForFun: false } }
          ],
          ...filter

        },
      },
      sort: [
        { "tsCompleted": { "order": "asc", "format": "strict_date_optional_time_nanos", "numeric_type": "date_nanos" } }
      ],
      ...search_after
    })
      .then(({ hits }) => {

        if (hits.hits.length > 0) {
          const sortAvailable = hits.hits[hits.hits.length - 1]["sort"] != undefined;
          const sortObjExists = Array.isArray(hits.hits[hits.hits.length - 1]["sort"]) && (hits.hits[hits.hits.length - 1]["sort"].length > 0)
          if (sortAvailable && sortObjExists) {
            setLastTimeStamp(hits.hits[hits.hits.length - 1].sort[0])
          }
        }
        return hits.hits.map(({ _source }) => _source);
      })
      .then((moreGameRounds) => {
        const unSortedGameRounds = isNew ? [...moreGameRounds] : [...gameRounds, ...moreGameRounds];
        setunsortedGameRounds(unSortedGameRounds);
        const sortedGameRounds = unSortedGameRounds.sort(function (a, b) { return a.gameRoundId - b.gameRoundId });
        setGameRounds(sortedGameRounds);
        setVisible(false);
        // window.localStorage.setItem('GameRoundHistory', JSON.stringify([...(isNew ? [] : gameRounds), ...moreGameRounds]))
        setHasMore(moreGameRounds.length > 0);
      });

  };
  const formatDataForCSV = gameRounds => {
    const formattedGameRounds = [];
    gameRounds.forEach(gameRound => {
      const formattedGameRound = {};
      const localDate = new Date(gameRound.tsCreated);
      let day = localDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let month = (localDate.getMonth() + 1);
      month = month < 10 ? `0${month}` : month;
      const year = localDate.getFullYear();
      formattedGameRound['Date'] = `${day}-${month}-${year}`;
      formattedGameRound['Game Round Id'] = gameRound.gameRoundId;
      formattedGameRound['Player Id'] = gameRound.playerId;
      formattedGameRound['Game Id'] = gameRound.gameId;
      formattedGameRound['Mode'] = gameRound.isPlayForFun ? 'fun' : 'real';
      formattedGameRound['Currency'] = gameRound.currency;
      formattedGameRound['Start Date'] = new Date(gameRound.tsCreated).toLocaleString();
      formattedGameRound['End Date'] = new Date(gameRound.tsCompleted).toLocaleString();
      formattedGameRound['STAKE'] = gameRound.totalStake;
      formattedGameRound['WIN'] = gameRound.totalReturn;
      formattedGameRound['GGR'] = gameRound.totalStake - gameRound.totalReturn;
      formattedGameRounds.push(formattedGameRound);
    });
    return formattedGameRounds;
  }
  return (
    <>
      <form className="formLabel">
        <div className="form-group row mt-3">
          <div className="col-sm-6">
            <input className="form-control" defaultValue={tsCreatedFrom.current && tsCreatedFrom.current.value} type="date" ref={tsCreatedFrom} />
          </div>
          <div className="col-sm-6">
            <input className="form-control" defaultValue={tsCreatedTo.current && tsCreatedTo.current.value} type="date" ref={tsCreatedTo} />
          </div>
        </div>
        <div className="form-group row">
        <div className="col-sm-2">
             <Dropdown value={environment == null ? "" : environment} className="form-control" onChange={onEnvironmentchange} options={environments} placeholder="Select Enviroment" type="text" />
          </div>
          <div className="col-sm-2">
            <input className="form-control" defaultValue={gameRoundId.current && gameRoundId.current.value} placeholder="gameRoundId" type="number" ref={gameRoundId} />
          </div>
          <div className="col-sm-2">
            <input className="form-control" defaultValue={playerId.current && playerId.current.value} placeholder="playerId" type="text" ref={playerId} />
          </div>
          {/* <div className="col-sm-3">
            <Dropdown value={environment == null ? "All Environment" : environment} className="form-control" onChange={onchange} options={environments} placeholder="Select Enviroment" type="text" />
          </div> */}
          <div className="col-sm-2">
            <Dropdown value={gameID == null ? "All Games" : gameID} className="form-control" onChange={onchange} options={gameIdArr} placeholder="Select GameID" type="text" ref={gameId} />
          </div>
          <div className="col-sm-2">
            <Dropdown value={currency == null ? "All Currencies" : currency} className="form-control" onChange={onCurrencyChange} options={currencies} placeholder="Select Currency" type="text" />
          </div>
          <div className="col-sm-2">
            <button className="btn btn-primary btn-block" type="button" onClick={() => search(true)}>
              Search
            </button>
          </div>
        </div>
        
      </form>


      <div>
        <h1 className='dashHeader'>Mini DashBoard <CSVLink filename={`Game Round History Report ${new Date().toLocaleString()}`} data={formatDataForCSV(gameRounds)}>Download Report</CSVLink></h1>


      </div>

      <div style={{ textAlign: 'center' }}>
        <div style={{ display: 'inline-block', textAlign: 'center' }}>
          <Rings height="100" width="100" color='#00BFFF' ariaLabel='loading' visible={visible} />
        </div>
      </div>

      <table className="table table-bordered table-hover table-striped">




        <thead>
          <tr>
            <td>totalbet</td>
            <td>totalwin</td>
            <td>totalGGR</td>
            <td>totalGames</td>
          </tr>
        </thead>


        <tbody>
          <tr>

          <td>{totalStake}</td>
            <td>{totalReturn}</td>
            <td>{totalGGR}</td>
            <td>{totalDocs}</td>

          </tr>
        </tbody>

      </table>
      <div>
        <h1 className='dashHeader'>Revenue Breakdown By Currency</h1>
      </div>
      <table className="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <td>currency</td>
            <td>totalbet</td>
            <td>totalwin</td>
            <td>totalGGR</td>
            <td>totalGames</td>
          </tr>
        </thead>


        <tbody>
          {
            currencyBucket.map(bucket => {
              return (<tr>
                <td>{bucket.currency}</td>
                <td>{bucket.totalStake}</td>
                <td>{bucket.totalReturn}</td>
                <td>{bucket.totalStake - bucket.totalReturn}</td>
                <td>{bucket.doc_count}</td>
              </tr>)
            })
          }
        </tbody>

      </table>
      <p>* Please scroll down to the end of the page to get the final consolidated numbers for the defined date range. </p>
      {gameRounds && (


        <InfiniteScroll
          dataLength={gameRounds.length}
          next={() => search(false)}
          hasMore={hasMore}
        >
          <table className="table table-bordered table-hover table-striped">

            <thead>
              <tr>
              <td>gameRoundID</td>
                <td>playerId</td>
                <td>gameId</td>
                <td>mode</td>
                <td>currency</td>
                <td>startDate</td>
                <td>endDate</td>
                <td>Stake</td>
                <td>Win</td>
                <td>GGR</td>
              </tr>
            </thead>
            <tbody>
              {gameRounds.map(
                ({
                  gameRoundId,
                  playerId,
                  gameId,
                  isPlayForFun,
                  currency,
                  tsCreated,
                  tsCompleted,
                  totalStake,
                  totalReturn,
                }) => (
                  <tr key={gameRoundId}>
                    <td>
                      <Link to={`/gameRoundHistory/${gameRoundId}`}>{gameRoundId}</Link> (
                      <Link to={`/log/${gameRoundId}`}>log</Link>)
                    </td>
                    <td>{playerId}</td>
                    <td>{gameId}</td>
                    <td>{isPlayForFun ? 'fun' : 'real'}</td>
                    <td>{currency}</td>
                    <td>{new Date(tsCreated).toLocaleString()}</td>
                    <td>{tsCompleted && new Date(tsCompleted).toLocaleString()}</td>
                    <td>{totalStake}</td>
                    <td>{totalReturn}</td>
                    <td>{totalStake - totalReturn}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </InfiniteScroll>
      )}
      <div>
        <h1 className='dashHeader'>Mini DashBoard <CSVLink filename={`Game Round History Report ${new Date().toLocaleString()}`} data={formatDataForCSV(gameRounds)}>Download Report</CSVLink></h1>
      </div>
      <table className="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <td>totalbet</td>
            <td>totalwin</td>
            <td>totalGGR</td>
            <td>totalGames</td>
          </tr>
        </thead>
        <tbody>
          <tr>
          <td>{totalStake}</td>
            <td>{totalReturn}</td>
            <td>{totalGGR}</td>
            <td>{totalDocs}</td>
          </tr>
        </tbody>
      </table>
      <p style={{ textAlign: 'center' }}>
        <b>The End</b>
      </p>
    </>
  );
};

export default GameRounds;
