import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import invoke from '../rpc';
import Config from '../constants';

const invokeGameService = invoke('GameService');
const Environments = () => {
  const [games, setGames] = useState([]);
  const [enName, setEnName] = useState('');
  const [enPath, setEnPath] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const [allEnviroment, setallEnviroment] = useState(
    JSON.parse(window.localStorage.getItem('AllEnvironmentConfigNew')) || Config.EnvironmentConfig
  );
  const [environment, setEnvironment] = useState(
    JSON.parse(window.localStorage.getItem('GameRoundEnvironmentId')) || null
  );
  const [environmentPath, setEnvironmentPath] = useState(
    JSON.parse(window.localStorage.getItem('GameRoundEnvironmentPath')) || null
  );

  const AddEnvironment = () => {
    if (enName && enPath) {
      const allEnv = [...allEnviroment];
      const newEnv = {
        _id: allEnv.length + 1,
        name: enName,
        path: enPath,
      };
      allEnv.push(newEnv);
      setallEnviroment(allEnv);
      setEnName('');
      setEnPath('');
      window.localStorage.setItem('AllEnvironmentConfigNew', JSON.stringify(allEnv));
    }
  };

  const Cancel = () => {
    setIsUpdate(false);
    setEnName('');
    setEnPath('');
    setCurrentId(null);
  };

  const UpdateEnvironment = () => {
    if (enName && enPath && currentId) {
      const allEnv = [...allEnviroment];
      const newEnv = {
        _id: currentId + 1,
        name: enName,
        path: enPath,
      };
      allEnv.splice(currentId, 1, newEnv);
      setallEnviroment(allEnv);
      setEnName('');
      setEnPath('');
      setCurrentId(null);
      setIsUpdate(false);
      window.localStorage.setItem('AllEnvironmentConfigNew', JSON.stringify(allEnv));
    }
  };
  const onUpdate = (id) => {
    setIsUpdate(true);
    setCurrentId(id);
    setEnName(allEnviroment[id].name);
    setEnPath(allEnviroment[id].path);
  };
  return (
    <>
      {!isUpdate && (
        <form>
          <div></div>
          <div className="form-group row mt-3">
            <div className="col-sm-3">
              <input
                required
                type="text"
                name="enName"
                id="enName"
                value={enName}
                placeholder="Environment Name"
                // style={{ width: '200px' }}
                onChange={(e) => setEnName(e.target.value)}
              />
            </div>
            <div className="col-sm-3">
              <input
                required
                type="text"
                name="enPath"
                id="enPath"
                value={enPath}
                placeholder="Environment Path"
                onChange={(e) => setEnPath(e.target.value)}
              />
            </div>
            <div className="col-sm-2">
              <button className="btn btn-primary" type="button" onClick={AddEnvironment}>
                Add
              </button>
            </div>
          </div>
        </form>
      )}
      {isUpdate && (
        <form>
          <div></div>
          <div className="form-group row mt-3">
            <div className="col-sm-3">
              <input
                required
                type="text"
                name="enName"
                id="enName"
                value={enName}
                placeholder="Environment Name"
                onChange={(e) => setEnName(e.target.value)}
              />
            </div>
            <div className="col-sm-3">
              <input
                required
                type="text"
                name="enPath"
                id="enPath"
                value={enPath}
                placeholder="Environment Path"
                onChange={(e) => setEnPath(e.target.value)}
              />
            </div>
            <div className="col-sm-2">
              <button className="btn btn-primary" type="button" onClick={UpdateEnvironment}>
                Update
              </button>
            </div>
            <div className="col-sm-2">
              <button className="btn btn-primary" type="button" onClick={Cancel}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}
      {!isUpdate && (
        <table className="table table-sm table-bordered table-hover table-striped">
          <caption>Environments</caption>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Environment Name</th>
              <th scope="col">Environment Path</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {allEnviroment.map(({ _id, name, path }, index) => (
              <tr key={index}>
                <td>{_id}</td>
                <td>{name}</td>
                <td>{path}</td>
                <td>
                  <button className="btn btn-primary" onClick={() => onUpdate(index)}>
                    {'Update'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default Environments;
